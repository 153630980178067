.tooltip {
  overflow: visible;
}

.tooltip .help-text {
  display: none;
}

.tooltip:hover .help-text {
  position: absolute;
  display: block;
  background-color: var(--ion-color-light);
  z-index: 2; /* Need to come out over top of any containing elements. */
  padding: .25em;
  border: 1px solid var(--ion-color-primary);
  border-radius: .25em;
}

.tooltip:hover .help-text p{
  margin-bottom: 0;
}

.help-icon {
  color: var(--ion-color-primary);
  pointer-events: none;
}