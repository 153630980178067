.logo {
  width: 10em;
}

.big-logo {
  width: 20em;
}

ion-grid{
  height: 100%;
}
ion-row{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
}