img {
  display: block;
  margin: auto;
}

ion-card {
  max-width: 50em;
  margin: auto;
  margin-bottom: 2em;
}

/* I think this overrides the more local rules from ionic for p's inside cards.  */
/* Centered buttons should be placed inside p's and then set to text-align:center */
/* TODO: Not sure this is working with the current setup. */
ion-card-content > p,
.card-content-md p,
.card-content-ios p {
  /*Selects only immediate children. p's inside grids, etc. are unaffected.*/
  margin: auto;
  margin-bottom: 1em;
}

ion-card-content > h1,
.card-content-md h1,
.card-content-ios h1,
ion-card-content > h2,
.card-content-md h2,
.card-content-ios h2 {
  /*Selects only immediate children. p's inside grids, etc. are unaffected.*/
  margin: auto;
}

/* Custom css class for interactive elements: */
.clickable {
  cursor: pointer;
}